"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_FAMILY_DATA = void 0;
var client_1 = require("@apollo/client");
exports.GET_FAMILY_DATA = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query downloadFamily(\n    $country: String\n    $province: String\n    $commune: String\n    $zone: String\n    $colline: String\n    $locality: String\n    $group: String\n  ) {\n    downloadFamily(\n      country: $country\n      province: $province\n      commune: $commune\n      zone: $zone\n      colline: $colline\n      locality: $locality\n      user_group: $group\n    )\n  }\n"], ["\n  query downloadFamily(\n    $country: String\n    $province: String\n    $commune: String\n    $zone: String\n    $colline: String\n    $locality: String\n    $group: String\n  ) {\n    downloadFamily(\n      country: $country\n      province: $province\n      commune: $commune\n      zone: $zone\n      colline: $colline\n      locality: $locality\n      user_group: $group\n    )\n  }\n"])));
var templateObject_1;
