"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_SERVICE_CATEGORY = exports.UPSERT_SERVICE_CATEGORY = exports.UPSERT_SERVICE = exports.DELETE_SERVICE = exports.DELETE_SERVICE_CENTER = exports.UPSERT_SERVICE_CENTER = void 0;
var client_1 = require("@apollo/client");
var UPSERT_SERVICE_CENTER = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation bulkUpsertServiceCenter($input: [addServiceCenterPayload]) {\n    bulkUpsertServiceCenter(input: $input)\n  }\n"], ["\n  mutation bulkUpsertServiceCenter($input: [addServiceCenterPayload]) {\n    bulkUpsertServiceCenter(input: $input)\n  }\n"])));
exports.UPSERT_SERVICE_CENTER = UPSERT_SERVICE_CENTER;
var DELETE_SERVICE_CENTER = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation deleteServiceCenter($id: ID, $organization_id: ID) {\n    deleteServiceCenter(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteServiceCenter($id: ID, $organization_id: ID) {\n    deleteServiceCenter(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.DELETE_SERVICE_CENTER = DELETE_SERVICE_CENTER;
var UPSERT_SERVICE = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation upsertService(\n    $organization_id: ID!\n    $service_id: ID\n    $service_center_id: ID!\n    $name: String!\n    $service_media: ServiceMediaInput!\n    $sorting: Int\n    $description: String\n    $service_categories: [ServiceCategoriesInput!]!\n  ) {\n    upsertService(\n      organization_id: $organization_id\n      service_id: $service_id\n      service_center_id: $service_center_id\n      name: $name\n      service_media: $service_media\n      sorting: $sorting\n      description: $description\n      service_categories: $service_categories\n    )\n  }\n"], ["\n  mutation upsertService(\n    $organization_id: ID!\n    $service_id: ID\n    $service_center_id: ID!\n    $name: String!\n    $service_media: ServiceMediaInput!\n    $sorting: Int\n    $description: String\n    $service_categories: [ServiceCategoriesInput!]!\n  ) {\n    upsertService(\n      organization_id: $organization_id\n      service_id: $service_id\n      service_center_id: $service_center_id\n      name: $name\n      service_media: $service_media\n      sorting: $sorting\n      description: $description\n      service_categories: $service_categories\n    )\n  }\n"])));
exports.UPSERT_SERVICE = UPSERT_SERVICE;
var DELETE_SERVICE = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation deleteService(\n    $id: ID!\n    $organization_id: ID!\n    $service_center_id: ID!\n  ) {\n    deleteService(\n      id: $id\n      organization_id: $organization_id\n      service_center_id: $service_center_id\n    )\n  }\n"], ["\n  mutation deleteService(\n    $id: ID!\n    $organization_id: ID!\n    $service_center_id: ID!\n  ) {\n    deleteService(\n      id: $id\n      organization_id: $organization_id\n      service_center_id: $service_center_id\n    )\n  }\n"])));
exports.DELETE_SERVICE = DELETE_SERVICE;
var UPSERT_SERVICE_CATEGORY = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation bulkUpsertServiceCategory($input: [addServiceCategoryPayload]) {\n    bulkUpsertServiceCategory(input: $input)\n  }\n"], ["\n  mutation bulkUpsertServiceCategory($input: [addServiceCategoryPayload]) {\n    bulkUpsertServiceCategory(input: $input)\n  }\n"])));
exports.UPSERT_SERVICE_CATEGORY = UPSERT_SERVICE_CATEGORY;
var DELETE_SERVICE_CATEGORY = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation deleteServiceCategory($id: ID, $organization_id: ID) {\n    deleteServiceCategory(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteServiceCategory($id: ID, $organization_id: ID) {\n    deleteServiceCategory(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.DELETE_SERVICE_CATEGORY = DELETE_SERVICE_CATEGORY;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
