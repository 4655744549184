"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_KNOWLEDGE_CATEGORY = exports.GET_KNOWLEDGE = void 0;
var client_1 = require("@apollo/client");
var GET_KNOWLEDGE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getKnowledgeCategoryByOrganization($organization_id: ID!) {\n    getKnowledgeCategoryByOrganization(organization_id: $organization_id) {\n      id\n      name\n      icon_web\n      icon\n      sorting\n      organization_id\n      knowledge {\n        id\n        title\n        description\n        sorting\n        knowledge_category_id\n        knowledge_media {\n          id\n          thumbnail_link\n          video_link\n          web_thumbnail_link\n          web_video_link\n        }\n      }\n    }\n  }\n"], ["\n  query getKnowledgeCategoryByOrganization($organization_id: ID!) {\n    getKnowledgeCategoryByOrganization(organization_id: $organization_id) {\n      id\n      name\n      icon_web\n      icon\n      sorting\n      organization_id\n      knowledge {\n        id\n        title\n        description\n        sorting\n        knowledge_category_id\n        knowledge_media {\n          id\n          thumbnail_link\n          video_link\n          web_thumbnail_link\n          web_video_link\n        }\n      }\n    }\n  }\n"])));
exports.GET_KNOWLEDGE_CATEGORY = GET_KNOWLEDGE_CATEGORY;
var GET_KNOWLEDGE = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getKnowledgeByOrganization($knowledge_category_id: ID!, $organization_id: ID!) {\n    getKnowledgeByOrganization(\n      knowledge_category_id: $knowledge_category_id\n      organization_id: $organization_id\n    ) {\n      id\n      sorting\n      title\n      description\n      knowledge_media {\n        thumbnail_link\n        video_link\n      }\n      knowledge_category_id\n    }\n  }\n"], ["\n  query getKnowledgeByOrganization($knowledge_category_id: ID!, $organization_id: ID!) {\n    getKnowledgeByOrganization(\n      knowledge_category_id: $knowledge_category_id\n      organization_id: $organization_id\n    ) {\n      id\n      sorting\n      title\n      description\n      knowledge_media {\n        thumbnail_link\n        video_link\n      }\n      knowledge_category_id\n    }\n  }\n"])));
exports.GET_KNOWLEDGE = GET_KNOWLEDGE;
var templateObject_1, templateObject_2;
