"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOGIN = void 0;
var client_1 = require("@apollo/client");
exports.LOGIN = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation Login(\n    $username: String!\n    $password: String!\n    $role: ID\n    $country_code: String\n  ) {\n    login(\n      input: {\n        username: $username\n        password: $password\n        role: $role\n        country_code: $country_code\n      }\n    ) {\n      user {\n        id\n        name\n        token\n        roles {\n          id\n          name\n        }\n        detail {\n          userid\n          first_name\n          last_name\n          gender\n          address_country\n          address_state\n          address_zone\n          address_zone1\n          address_city\n          address_locality\n          user_group\n        }\n        accounts {\n          account_id\n          bank_account {\n            bank_id\n            bank {\n              bank_name\n              bank_code\n            }\n          }\n        }\n      }\n    }\n  }\n"], ["\n  mutation Login(\n    $username: String!\n    $password: String!\n    $role: ID\n    $country_code: String\n  ) {\n    login(\n      input: {\n        username: $username\n        password: $password\n        role: $role\n        country_code: $country_code\n      }\n    ) {\n      user {\n        id\n        name\n        token\n        roles {\n          id\n          name\n        }\n        detail {\n          userid\n          first_name\n          last_name\n          gender\n          address_country\n          address_state\n          address_zone\n          address_zone1\n          address_city\n          address_locality\n          user_group\n        }\n        accounts {\n          account_id\n          bank_account {\n            bank_id\n            bank {\n              bank_name\n              bank_code\n            }\n          }\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
