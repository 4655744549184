"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SERVICE_CENTER_BY_ORGANIZATION = exports.GET_SERVICE_CATEGORY = exports.GET_ADDRESS_LIST = exports.GET_ADDRESS_LABEL = void 0;
var client_1 = require("@apollo/client");
var GET_SERVICE_CENTER_BY_ORGANIZATION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getAllServiceCenterByOrganization($organization_id: ID!) {\n    getAllServiceCenterByOrganization(organization_id: $organization_id) {\n      id\n      organization_id\n      name\n      icon\n      icon_web\n      phone\n      website\n      email\n      area1\n      area2\n      area3\n      area4\n      area5\n      group\n      sorting\n      service_categories {\n        id\n        name\n      }\n      services {\n        id\n        name\n        icon_web\n        icon\n        video\n        thumbnail\n        video_web\n        thumbnail_web\n        description\n        sorting\n        service_center_id\n        organization_id\n        service_categories {\n          id\n          name\n        }\n      }\n    }\n  }\n"], ["\n  query getAllServiceCenterByOrganization($organization_id: ID!) {\n    getAllServiceCenterByOrganization(organization_id: $organization_id) {\n      id\n      organization_id\n      name\n      icon\n      icon_web\n      phone\n      website\n      email\n      area1\n      area2\n      area3\n      area4\n      area5\n      group\n      sorting\n      service_categories {\n        id\n        name\n      }\n      services {\n        id\n        name\n        icon_web\n        icon\n        video\n        thumbnail\n        video_web\n        thumbnail_web\n        description\n        sorting\n        service_center_id\n        organization_id\n        service_categories {\n          id\n          name\n        }\n      }\n    }\n  }\n"])));
exports.GET_SERVICE_CENTER_BY_ORGANIZATION = GET_SERVICE_CENTER_BY_ORGANIZATION;
var GET_ADDRESS_LABEL = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getAddressLabel {\n    getAddressLabel {\n      country_code\n      country\n      area1\n      area2\n      area3\n      area4\n      area5\n      group\n    }\n  }\n"], ["\n  query getAddressLabel {\n    getAddressLabel {\n      country_code\n      country\n      area1\n      area2\n      area3\n      area4\n      area5\n      group\n    }\n  }\n"])));
exports.GET_ADDRESS_LABEL = GET_ADDRESS_LABEL;
var GET_ADDRESS_LIST = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query getAddressList(\n    $area1: String\n    $area2: String\n    $area3: String\n    $area4: String\n    $area5: String\n    $group: String\n  ) {\n    getAddressList(\n      area1: $area1\n      area2: $area2\n      area3: $area3\n      area4: $area4\n      area5: $area5\n      group: $group\n    ) {\n      area1\n      area2\n      area3\n      area4\n      area5\n      group\n    }\n  }\n"], ["\n  query getAddressList(\n    $area1: String\n    $area2: String\n    $area3: String\n    $area4: String\n    $area5: String\n    $group: String\n  ) {\n    getAddressList(\n      area1: $area1\n      area2: $area2\n      area3: $area3\n      area4: $area4\n      area5: $area5\n      group: $group\n    ) {\n      area1\n      area2\n      area3\n      area4\n      area5\n      group\n    }\n  }\n"])));
exports.GET_ADDRESS_LIST = GET_ADDRESS_LIST;
var GET_SERVICE_CATEGORY = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query getAllServiceCategoriesByOrganization($organization_id: ID!) {\n    getAllServiceCategoriesByOrganization(organization_id: $organization_id) {\n      id\n      name\n      sorting\n      organization_id\n    }\n  }\n"], ["\n  query getAllServiceCategoriesByOrganization($organization_id: ID!) {\n    getAllServiceCategoriesByOrganization(organization_id: $organization_id) {\n      id\n      name\n      sorting\n      organization_id\n    }\n  }\n"])));
exports.GET_SERVICE_CATEGORY = GET_SERVICE_CATEGORY;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
