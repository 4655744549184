"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_KNOWLEDGE = exports.DELETE_KNOWLEDGE = exports.UPSERT_KNOWLEDGE_CATEGORY = exports.DELETE_KNOWLEDGE_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var DELETE_KNOWLEDGE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation deleteKnowledgeCategory($id: ID!, $organization_id: ID!) {\n    deleteKnowledgeCategory(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteKnowledgeCategory($id: ID!, $organization_id: ID!) {\n    deleteKnowledgeCategory(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.DELETE_KNOWLEDGE_CATEGORY = DELETE_KNOWLEDGE_CATEGORY;
var UPSERT_KNOWLEDGE_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation upsertKnowledgeCategory($input: [knowledgePayload!]!) {\n    upsertKnowledgeCategory(input: $input)\n  }\n"], ["\n  mutation upsertKnowledgeCategory($input: [knowledgePayload!]!) {\n    upsertKnowledgeCategory(input: $input)\n  }\n"])));
exports.UPSERT_KNOWLEDGE_CATEGORY = UPSERT_KNOWLEDGE_CATEGORY;
var DELETE_KNOWLEDGE = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation deleteKnowledge(\n    $organization_id: ID!\n    $knowledge_category_id: ID\n    $id: ID!\n  ) {\n    deleteKnowledge(\n      id: $id\n      organization_id: $organization_id\n      knowledge_category_id: $knowledge_category_id\n    )\n  }\n"], ["\n  mutation deleteKnowledge(\n    $organization_id: ID!\n    $knowledge_category_id: ID\n    $id: ID!\n  ) {\n    deleteKnowledge(\n      id: $id\n      organization_id: $organization_id\n      knowledge_category_id: $knowledge_category_id\n    )\n  }\n"])));
exports.DELETE_KNOWLEDGE = DELETE_KNOWLEDGE;
var UPSERT_KNOWLEDGE = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation upsertKnowledge(\n    $knowledge_id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $knowledge_category_id: ID!\n    $knowledge_media: KnowledgeMediaInput!\n    $organization_id: ID!\n  ) {\n    upsertKnowledge(\n      knowledge_id: $knowledge_id\n      title: $title\n      description: $description\n      sorting: $sorting\n      knowledge_category_id: $knowledge_category_id\n      knowledge_media: $knowledge_media\n      organization_id: $organization_id\n    )\n  }\n"], ["\n  mutation upsertKnowledge(\n    $knowledge_id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $knowledge_category_id: ID!\n    $knowledge_media: KnowledgeMediaInput!\n    $organization_id: ID!\n  ) {\n    upsertKnowledge(\n      knowledge_id: $knowledge_id\n      title: $title\n      description: $description\n      sorting: $sorting\n      knowledge_category_id: $knowledge_category_id\n      knowledge_media: $knowledge_media\n      organization_id: $organization_id\n    )\n  }\n"])));
exports.UPSERT_KNOWLEDGE = UPSERT_KNOWLEDGE;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
